@charset "UTF-8";


* {
    margin: 0;
    padding: 0;
}

html {
    background-color: #fff;
}

body {
    margin: 0;
    padding: 0;
    font-family: Avenir, "Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, Roboto, "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

    font-size: 12px;
    line-height: 1.4;
    color: #555;
    _text-align: center;
    margin: auto;
}

br {
    letter-spacing: normal;
}

a {
    color: #a91b4b;
    text-decoration: underline;
}

a:hover {
    color: #dd3f75;
}

img {
    border: 0;
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
}

table caption {
    display: none;
}

ul li {
    list-style-type: none;
}

h2 a {
    text-decoration: none;
}

/* 汎用 ----------------*/
.clearfix {
    width: 100%;
}

.clearfix:after {
    content: "";
    display: block;
    clear: both;
}

/* 汎用マージン */
.mt05 {
    margin-top: 5px !important;
}

.mt08 {
    margin-top: 8px;
}

.mt10 {
    margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

.mt20 {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.mt30 {
    margin-top: 30px;
}

.mt35 {
    margin-top: 35px !important;
}

.mt40 {
    margin-top: 40px;
}

.mt45 {
    margin-top: 45px;
}

.mt50 {
    margin-top: 50px !important;
}

.mt65 {
    margin-top: 65px;
}

.mb00 {
    margin-bottom: 0px !important;
}

.mb05 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb12 {
    margin-bottom: 12px;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb25 {
    margin-bottom: 25px;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb35 {
    margin-bottom: 35px;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb50 {
    margin-bottom: 50px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb90 {
    margin-bottom: 90px;
}

.noMargin {
    margin: 0 !important;
}

.pt15 {
    padding-top: 15px;
}

.pt100 {
    padding-top: 100px;
}

.pt150 {
    padding-top: 150px;
}

.pt200 {
    padding-top: 200px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb280 {
    padding-bottom: 280px;
}

/* 汎用テキスト */
.bold {
    font-weight: bold !important;
}

.f-weight500 {
    font-weight: 500;
}

.f-weight400 {
    font-weight: 400;
}

.center {
    text-align: center;
}

.right {
    text-align: right !important;
}

.font10 {
    font-size: 10px;
}

.font11 {
    font-size: 12px;
}

.font12 {
    font-size: 12px;
}

.font13 {
    font-size: 13px;
    padding-bottom: 15px;
}

.font14 {
    font-size: 14px;
    padding: 30px 0 10px 0;
}

.fs14 {
    font-size: 14px;
}

.font15 {
    font-size: 15px;
}

.font16 {
    font-size: 16px;
}

.font17 {
    font-size: 17px;
}

.font18 {
    font-size: 18px;
}

.font20 {
    font-size: 20px;
}

.font24 {
    font-size: 24px;
}

.font28 {
    font-size: 28px;
}

.font32 {
    font-size: 32px;
}

.font36 {
    font-size: 36px;
}

.gray {
    color: #696969;
}

.pink {
    color: #b32353;
}

.orange {
    color: #c2730d;
}

.red {
    color: #F01F27;
}

.mGray {
    color: #444;
}

.w600 {
    width: 600px;
    margin: 0 auto;
}

.pkTriangle {
    background: url("/images/bg_triangle_pk.png") no-repeat left;
    padding-left: 10px;
    display: inline;
    padding-left: 13px;
}

/* 角丸 */
.cssParsedBox {
    margin: 0;
    padding: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    CCborderRadius: 4px;
}
/*位置*/
.align--center {
    text-align: center;
}

/*-------------------------------------------------------
#header
-------------------------------------------------------*/
#header {
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
}

#hdC {
    width: 100%;
    padding: 3px 0;
    background: #a91b4b;
}

.hdcInner {
    width: 1100px;
    margin: 0 auto;
}

.hdcInner h1 {
    color: #fff;
    font-size: 84%;
    font-weight: normal;
}

.hdInner {
    width: 1100px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
    overflow: hidden;
    _zoom: 1;
    _text-align: left;
}

.hdInner h2 {
    width: 300px;
    float: left;
}

.hdR {
    float: right;
    overflow: hidden;
    _zoom: 1;
}

.hdMenu {
    padding-top: 0px;
    float: left;
}

.hdMenu ul {
    overflow: hidden;
    padding-right: 40px;
    _zoom: 1;
}

.hdMenu ul li {
    padding-left: 12px;
    margin-right: 12px;
    float: left;
    background: url(../../public/images/ico_arrow.gif) left center no-repeat;
    font-size: 92%;
}

.hdMenu ul .last {
    margin-right: 0;
}

.hdMenu ul li a {
    color: #414141;
    text-decoration: none;
    font-size: 13px;
}

.hdMenu ul li a:hover {
    color: #A91B4B;
}

.hdInq {
    width: 165px;
    float: right;
}

/*-------------------------------------------------------
#nav
-------------------------------------------------------*/

.header-nav {
    border-bottom: 5px solid #ccc;
    position: relative;
}

.header-nav ul {
    position: absolute;
    left: 10%;
    display: table;
    margin: 0 auto;
    padding: 0;
    width: 80%;
    text-align: center;
}

.header-nav ul li {
    display: table-cell;
    min-width: 50px;
}

.header-nav ul li a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    color: #414141;
    font-size: 15px;
    text-decoration: none;
}

.header-nav ul li.is-active {
    border-top: 5px solid #a91b4b;
    font-weight: bold;
}

.header-nav ul li.is-active a {
    background: rgb(169, 27, 75, 0.2);
}

.header-nav .navTOP {
    font-weight: bold;
    border-top: 5px solid #a91b4b;
}

.header-nav ul li a:hover {
    color: rgb(65, 65, 65, 0.7);
    background: rgb(169, 27, 75, 0.2);
}

.header-nav ul li:hover {
    border-top: 5px solid rgb(169, 27, 75, 0.6);
}

/*-----------------------------------------------
headBnr
-----------------------------------------------*/
#hdBnrWrap {
    _text-align: center;
}

#headBnr {
    width: 860px;
    margin: 0 auto 15px;
    _text-align: left;
}

/* div#wrapper ================== */
#wrapper {
    clear: both;
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
}
#wrapper.sub_wrapper {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 100px;
}

#contents {
    width: 1200px;
    _float: right;
    margin: 0 auto;
    padding: 0;
}
#contents.sub_contents {
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    float: none;
    padding: 0 40px;
    box-sizing: border-box;
}

/* div.top-page================== */
.top-page {
    width: 1000px;
    margin: auto;
}


/*-------------------------------------------------------
#sideMenu
-------------------------------------------------------*/
#sideMenu {
    width: 193px;
    float: left;
    margin: 0;
    padding: 2px 0 0;
}

#sideMenu ul {
    list-style: none;
}

#sideMenu .to_data {
    padding-top: 2px;
    margin-bottom: 8px;
}

#sideMenu .menuBox {
    background: url(../../public/images/bg_side_shadow.gif) no-repeat left bottom;
    padding-bottom: 16px;
    margin-bottom: 20px;
    overflow: hidden;
    _zoom: 1;
}

#sideMenu .menuList {
    border-left: solid 1px #c2c2c2;
    border-right: solid 1px #c2c2c2;
    padding: 0;
}

#sideMenu .menuOther {
    border-top: solid 1px #c2c2c2;
}

#sideMenu .menuList h4 {
    padding: 10px;
    border-bottom: solid 1px #ebebeb;
    background: #ffb9cc;
    font-size: 135%;
    color: #333;
}

#sideMenu .menuList ul a {
    color: #555;
    font-weight: bold;
    text-decoration: none;
}

#sideMenu .menuList ul a:hover {
    color: #a91b4b;
}

.bnrArea ul {
    margin-bottom: 20px;
}

.bnrArea ul li {
    margin-bottom: 10px;
    text-align: center;
}

.bnrArea ul .bnrDv {
    margin-left: -2px;
}


/* ul.iconList */
#sideMenu .iconList {
    padding: 10px 0 3px;
}

#sideMenu .iconList li {
    background: url("../../public/images/ico_arrow.gif") no-repeat 11px 2px;
    line-height: 120%;
    margin-bottom: 10px;
    padding-left: 28px;
    font-size: 110%;

}

#sideMenu .iconList li span img {
    padding-left: 3px;
    vertical-align: middle;
}

/* p#postageSrv */
div#wrapper div#sideMenu div.menuList div#postageSrv {
    color: #b32353;
    font-size: 13px;
    line-height: 140%;
    padding-bottom: 5px;
    text-align: justify;
}

div#wrapper div#sideMenu div.menuList div#postageSrv p {
    border: 4px solid #B32353;
    margin: 0 auto;
    padding: 5px 10px;
    width: 155px;
}

/* ul.easyList */
div#wrapper div#sideMenu div.menuList ul.easyList {
    /*background: url("../../public/images/bg_side_easylist.gif") no-repeat bottom;*/
    padding-bottom: 4px;
}

div#wrapper div#sideMenu div.menuList ul.easyList li {
    margin-left: 3px;
}

/* ul.banner */
div#wrapper div#sideMenu ul.banner {}

div#wrapper div#sideMenu ul.banner li {
    margin-bottom: 10px;
    text-align: center;
}

.specialBnr {
    border: 1px #c32258 solid;
    padding: 3px 0;
    margin: 0 0 20px 0;
    text-align: center;
}


/* div#contents
======================================= */

/* div#breadcrumb */
div#wrapper div#contents div#breadcrumb {
    margin: 7px 0 20px;
    padding: 0;
}

div#wrapper div#contents div#breadcrumb p {
    float: right;
    display: inline;
}

div#wrapper div#contents div#breadcrumb ul {
    list-style: none;
    padding-top: 15px;
    overflow: hidden;
    _zoom: 1;
}

div#wrapper div#contents div#breadcrumb ul li {
    background: url("../../public/images/breadcrumb_next.gif") no-repeat left center;
    display: inline;
    float: left;
    font-size: 92%;
    line-height: 1.4;
    margin-right: 6px;
    padding-left: 11px;
    color: #999;
}

div#wrapper div#contents div#breadcrumb ul li a {
    text-decoration: underline;
    color: #666;
}

div#wrapper div#contents div#breadcrumb ul li#home {
    background: none;
    padding-left: 5px;
}

div#wrapper div#contents div#breadcrumb ul li#home img {
    vertical-align: middle;
}

/*-----------------------------------------------
　#calendar
-----------------------------------------------*/

.calendar {
    position: relative;
    margin-top: 90px;
}
.calendar h3 {
    position: absolute;
    top: -45px;
    left: 0;
}
.calendar h3 figure {
    width: fit-content;
    position: relative;
}

.calendar h3 figure::after {
    content: "";
    width: 10px;
    height: 13px;
    background-color: #fff;
    position: absolute;
    right: -5px;
    bottom: -1px;
}

.calendarInner {
    padding: 15px 20px 20px;
    border: solid 1px #e1e1e1;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.calendarImg {
    width: 60%;
}

.calendarDetail {
    width: 210px;
    margin-left: 20px;
}


/* .footer
======================================= */
.footer {
    clear: both;
    width: 100%;
    min-width: 860px;
    background: url("../../public/images/bg_footer01.gif") repeat;
    font-size: 12px;
    color: #fff;
    border-top: 1px solid #971843;
    margin-top: 45px;
}

.footerGrad {
    background: url("../../public/images/bg_footer02.png") repeat-x bottom;
}

.footerInner {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    text-align: left;
}

.pagetop {
    height: 28px;
    float: right;
    width: 119px;
    margin-top: -2px;
}
.foot_menu {
    padding: 60px 0 35px;
}

.foot_menu_left {
    width: 270px;
    display: inline;
    float: left;
}

.foot_menu_left span {
    border-bottom: 1px solid #fff;
    color: #fff;
}

.map-menu:hover {
    opacity: 0.7;
}

p.mt20 {
    line-height: 180%;
}

.footerInner p a {
    color: #FFFFFF;
}

p.mt20 img {
    margin: 0 5px 2px 0;
}

address {
    color: #fff;
    opacity: 0.9;
    font-style: normal;
    padding-bottom: 10px;
}

.foot_menu_right {
    width: 560px;
    display: inline;
    float: right;
}

.foot_menu_right a {
    color: #fff;
    text-decoration: none;
}

.foot_menu_right a:hover {
    opacity: 0.7;
}

.footerInner ul {
    list-style: none;
    background: url("../../public/images/footer_bar.png") no-repeat right top;
    float: left;
    min-height: 178px;
    padding-left: 20px;
    padding-top: 15px;
    width: 165px;
}

.thirdList {
    background: none;
}

.footerInner ul li {
    background: url("../../public/images/footer_list.png") no-repeat scroll left 4px transparent;
    line-height: 130%;
    margin-bottom: 5px;
    padding-left: 12px;
}

.code{
    display: flex;
    justify-content: space-between;
}

/*-----------------------------------------------
各ページ共通
-----------------------------------------------*/

/*見出し*/
div#wrapper div#contents div#pageArea .pageTtl h2 {
    padding: 0 0 0 20px;
    margin: 0;
    float: left;
    line-height: 67px;
    font-size: 150%;
    font-weight: normal;
    color: #000;
}

.pageTtl {
    margin-bottom: 20px;
    border-top: solid 2px #666;
    border-bottom: dotted 1px #ccc;
    height: 66px;
    /*background: url(../../public/images/pagettl_bg2.gif) left top repeat-x;*/
    position: relative;
    overflow: hidden;
    _zoom: 1;
}

.pageTtl p {
    float: right;
    font-weight: bold;
    color: #ef382a;
    line-height: 66px;
}

.pageTtl ul {
    float: right;
    padding: 14px 10px 14px 0;
    margin-left: 20px;
}

.pageTtl ul li {
    display: inline;
}


.detailArea {
    margin-bottom: 40px;
}

.detailArea2 {
    margin-bottom: 30px;
}


/*キャッチコピーなど*/
.catchArea {
    margin-bottom: 40px;
}

/*キャッチコピーなど高さ指定ありの場合*/
.catchArea2 {
    margin-bottom: 40px;
    height: 200px;
}


/*h3*/
#pageArea h3 {
    padding-left: 35px;
    margin-bottom: 20px;
    background: url(/images/ttl_bg.gif) no-repeat left top / 100% 100%;
    height: 36px;
    line-height: 38px;
    font-size: 108%;
    color: #fff;
    _width: 1200px;
}

.subttl {
    padding: 4px 10px 6px;
    margin-bottom: 20px;
    border-left: solid 5px #9CCA47;
    border-bottom: solid 1px #ccc;
    font-size: 109%;
    color: #333;
}

/*補足*/
.supplement {
    padding: 10px 20px;
    border: solid 2px #777;
}

.supplement li {
    padding-left: 20px;
    background: url(../../public/images/ico_caution.gif) left center no-repeat;
    font-size: 93%;
    font-weight: bold;
    line-height: 1.5;
}

.bnr_case {
    margin-bottom: 5px;
}

/*-----------------------------------------------------------------------------------------------------------*/
.flexslider {
    margin: 0 auto 20px !important;
    width: 1000px;
}

.flex-caption {
    padding: 3%;
    left: 0;
    bottom: 0;
    background: #eac5d2;
    color: #fff;
    font-size: 9px;
    line-height: 16px;
    margin-top: 0;
}

/*-----------------------------------------------------------------------------------------------------------*/
.futo {
    width: calc(100% - 35px);
    max-width: 580px;
    margin-left: 35px;
    border-collapse: collapse;
}

.futo th {
    width: 25%;
    padding: 6px;
    text-align: center;
    vertical-align: top;
    color: #333;
    background-color: #eee;
    border: 1px solid #b9b9b9;
}

.futo td {
    padding: 6px;
    background-color: #fff;
    border: 1px solid #b9b9b9;
}

/*-----------------------------------------------------------------------------------------------------------*/
.nuno-left {
    float: left;
}

.nuno-right {
    float: left;
    margin-left: 10px;
}

.space {
    clear: left;
}

/*-----------------------------------------------------------------------------------------------------------*/
.nunotable1 {
    width: 100%;
    border-collapse: collapse;
}

.nunotable1 th {
    width: 25%;
    padding: 6px;
    text-align: left;
    vertical-align: top;
    color: #333;
    background-color: #eac5d2;
    border: 1px solid #b9b9b9;
}

.nunotable1 td {
    padding: 6px;
    background-color: #FFF5E9;
    border: 1px solid #b9b9b9;
}

/*-----------------------------------------------------------------------------------------------------------*/
.nunotable2 {
    width: 100%;
    border-collapse: collapse;
}

.nunotable2 th {
    width: 25%;
    padding: 6px;
    text-align: center;
    vertical-align: top;
    color: #333;
    background-color: #FEF0F3;
    border: 1px solid #b9b9b9;
}

.nunotable2 td {
    padding: 6px;
    text-align: right;
    background-color: #F3F2F1;
    border: 1px solid #b9b9b9;
}

/*-----------------------------------------------------------------------------------------------------------*/
hr {
    border-width: 1px 0px 0px 0px;
    /* 太さ1px */
    border-style: dashed;
    /* 線種を破線に */
    border-color: gray;
    /* 線色を灰色に */
    height: 1px;
    /* 高さ(※古いIE用) */
}

@media screen and (max-device-width: 480px) {
    html {
        zoom: 37.21%;
    }

    body {
        font-size: 180% !important;
    }
}

.post img {
    height: 214px;
}

.post2 {
    margin-right: 16px;
}

.option-flex {
    display: flex;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.marginauto {
    margin: auto;
}

.width653 {
    width: 653px;
}

.formBtnBox {
    display: flex;
    justify-content:space-around;
}

.nowTop a {
    text-decoration: none;
}
